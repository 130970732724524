import clsx from 'clsx';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import StaticMessages from '../../constants/staticMessages';
import { ButtonLoaderTypes } from '../../constants/types';
import { isClient } from '../../helpers/utils';
import monthlyIcon from '../../images/pricing/monthly-icon.png';
import Button from '../button';

import Links from '../btc-links';
import Checkbox from '../checkbox';
import styles from './checkout-total.module.scss';

const CheckoutTotal = ({
  onSubmit,
  cartData,
  showVat,
  showBtcDiscount,
  showAutoRenewalMessage,
  showInstallmentsButton,
  onInstallmentsPayment,
  showAgreement,
  electronicSignments,
  isPrimaryAddress,
  isPaymentSet,
  coinPaymentDiscount,
  ibpFluid,
  calculatedPrice,
  shouldSignAgreement,
}) => {
  const intl = useIntl();
  const checkoutTxt = intl.formatMessage({ id: 'purchase_checkout_total.checkout' });
  const safeAndSecureTxt = intl.formatMessage({
    id: 'purchase_checkout_total.safe_&_secure_payment',
  });
  const signAnAgreement = 'Sign an agreement';
  const submitTxt = intl.formatMessage({ id: 'purchase_checkout_total.submit_purchase' });
  const {
    products,
    subTotal,
    productCount,
    vatAmount,
    totalDiscount,
    processingFee,
    btcDiscountTotal,
    btcPriceTotal,
  } = cartData || {};

  const openInNewTab = () => {
    if (electronicSignments?.length) {
      // eslint-disable-next-line no-unused-expressions
      window.open(electronicSignments[0]?.url, '_blank')?.focus();
    }
  };
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 7000);

    return () => clearTimeout(timer);
  }, []);

  const total = calculatedPrice?.totalAmount || 0;
  const isSomeProductAnnual = products.some((p) => p.isAnnual);

  const isDisabled =
    isClient && (!productCount || (total > 0 && !isPaymentSet) || (!isPrimaryAddress && total > 0) || !isReady);

  const showCouponAppliedDiscount = !showBtcDiscount && totalDiscount > 0;

  return (
    <div className={styles.checkoutTotal}>
      <div className={styles.content}>
        <div className={styles.total}>
          <span className={styles.label}>{`${checkoutTxt} (${productCount} item)`}</span>
          <span className={clsx(styles.cost, 'dont-translate')}>
            ${showBtcDiscount && btcPriceTotal ? btcPriceTotal : total.toFixed(2)}
          </span>
        </div>
        <div className={styles.items}>
          {products.map((item) => (
            <CheckoutItem
              key={item.id}
              label={item.title}
              price={item?.isRenew ? item?.pricing?.price : item?.pricing?.price}
              bgColor={item.bgColor}
              color={item.color}
              icon={item.icon}
              intervalSize={item.intervalSize}
              bgType={`${item.productName}-${item.level}`.toLowerCase()}
              btcDiscount={showBtcDiscount && item.btcDiscount}
              btcPrice={showBtcDiscount && item.btcPrice}
              isAnnual={item.isAnnual}
              ibpFluid={ibpFluid}
              imageUrl={item.imageUrl}
            />
          ))}
        </div>
        {showBtcDiscount && !!btcDiscountTotal && (
          <div className={styles.fee}>
            <span className={styles.label}>Discount 5%</span>
            <span className={clsx(styles.text, 'dont-translate')}>
              ${btcDiscountTotal.toFixed(2)}
            </span>
          </div>
        )}
        {showCouponAppliedDiscount && (
          <div className={styles.fee}>
            <span className={styles.label}>Coupon applied:</span>
            <span className={clsx(styles.text, 'dont-translate')}>${totalDiscount.toFixed(2)}</span>
          </div>
        )}
        {showVat && showVat > 0 && (
          <div className={styles.fee}>
            <span className={styles.label}>Vat:</span>
            <span className={clsx(styles.text, 'dont-translate')}>${vatAmount || 0}</span>
          </div>
        )}
        <div className={styles.fee}>
          <span className={styles.label}>Processing fee:</span>
          <span className={clsx(styles.text, 'dont-translate')}>${processingFee || 0}</span>
        </div>
        {showAutoRenewalMessage && (
          <div className={clsx(styles.fee, styles.autoRenewalMessage)}>
            <span className={styles.label}>{StaticMessages.autoRenewalMessage}</span>
          </div>
        )}
        {coinPaymentDiscount?.message && (
          <div className={clsx(styles.fee)}>
            <span className={styles.btcText}>{coinPaymentDiscount?.message}</span>
          </div>
        )}
        <p className={styles.safePayment}>
          <i className="icon-lock" />
          <span>{safeAndSecureTxt}</span>
        </p>

        {shouldSignAgreement ? (
          <Button
            fullWidth
            size="large"
            disabled={isDisabled}
            className={styles.submitButton}
            onClick={showAgreement}
            loaderType={ButtonLoaderTypes.PURCHASE}
          >
            {signAnAgreement}
          </Button>
        ) : (
          <Button
            fullWidth
            size="large"
            disabled={isDisabled}
            loaderType={ButtonLoaderTypes.PURCHASE}
            className={styles.submitButton}
            onClick={onSubmit}
          >
            {submitTxt}
          </Button>
        )}

        {electronicSignments?.length === 1 && (
          <div className={styles.agreement}>
            <Checkbox onChange={() => { }} checked />
            <span>You have signed an agreement.</span>
            <div
              className={styles.agreementLink}
              role="button"
              tabIndex={0}
              onKeyDown={openInNewTab}
              onClick={openInNewTab}
            >
              Show in the new tab
            </div>
          </div>
        )}

        {electronicSignments?.length > 1 &&
          electronicSignments.map((e) => (
            <div className={styles.electronicSignListContainer} key={e.url}>
              <div
                className={styles.agreementLink}
                role="button"
                tabIndex={0}
                onKeyDown={() => window.open(e.url, '_blank')?.focus()}
                onClick={() => window.open(e.url, '_blank')?.focus()}
              >
                Open signed agreement
              </div>
            </div>
          ))}

        {coinPaymentDiscount?.links && (
          <Links cartAmount={subTotal} data={coinPaymentDiscount.links} type="iframe" />
        )}

        {showInstallmentsButton && (
          <div className={styles.dLocalButton}>
            <p className={styles.dLocalDivider}>
              <span>Or</span>
            </p>

            <Button
              fullWidth
              size="large"
              disabled={isDisabled}
              variant="transparent"
              className={styles.submitButton}
              onClick={onInstallmentsPayment}
            >
              <div className={styles.imageButton}>
                <img src={monthlyIcon} alt="" />
                <div>Pay Via Installment</div>
              </div>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

CheckoutTotal.defaultProps = { showBtcDiscount: false };

CheckoutTotal.propTypes = {
  showBtcDiscount: PropTypes.bool,
  electronicSignments: PropTypes.arrayOf(PropTypes.string).isRequired, // TODO: fix type
  showAgreement: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showVat: PropTypes.bool.isRequired,
  showInstallmentsButton: PropTypes.bool.isRequired,
  onInstallmentsPayment: PropTypes.func.isRequired,
  showAutoRenewalMessage: PropTypes.bool.isRequired,
  isPrimaryAddress: PropTypes.bool.isRequired,
  isPaymentSet: PropTypes.bool.isRequired,
  shouldSignAgreement: PropTypes.bool.isRequired,
  calculatedPrice: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ibpFluid: PropTypes.object.isRequired,
  coinPaymentDiscount: PropTypes.shape({
    message: PropTypes.string.isRequired,
    links: PropTypes.string.isRequired,
  }).isRequired,
  cartData: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        SKU: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        cvPoints: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired,
        levelId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        purchased: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        pricing: PropTypes.shape({
          price: PropTypes.number.isRequired,
          recurringPrice: PropTypes.number.isRequired,
        }),
        product: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          rebillyProductId: PropTypes.string.isRequired,
        }),
        productName: PropTypes.string.isRequired,
        rebillyPlanId: PropTypes.string.isRequired,
        recurringPrice: PropTypes.number.isRequired,
        recurringSKU: PropTypes.string.isRequired,
        setupPrice: PropTypes.number.isRequired,
      })
    ),
    subTotal: PropTypes.number.isRequired,
    productCount: PropTypes.number.isRequired,
  }).isRequired,
};

export default CheckoutTotal;

function CheckoutItem({
  label,
  price,
  recurrentPrice,
  // icon,
  // bgColor,
  // color,
  btcDiscount,
  btcPrice,
  isAnnual,
  intervalSize,
  ibpFluid,
  imageUrl,
}) {
  const intl = useIntl();

  const toMaintainTxt = intl.formatMessage({ id: 'purchase_checkout_total.to_maintain' });
  const aMonthAfterTxt = intl.formatMessage({ id: 'purchase_checkout_total.a_month_after' });
  const aYearAfterTxt = intl.formatMessage({ id: 'purchase_checkout_total.a_year_after' });
  const oneYearSubscription = intervalSize > 350;
  const recurringText = oneYearSubscription ? aYearAfterTxt : aMonthAfterTxt;
  // const btcDiscountMessage =
  //   'EXCLUSIVE DISCOUNT FOR LIMITED TIME ONLY - 10% Discount for one entire year';

  const itemPrice = btcPrice || price;

  return (
    <div className={styles.item}>
      <div className={styles.itemImageWrapper}>
        {imageUrl ? (
          <img className={styles.imageUrl} src={imageUrl} alt={label} />
        ) : (
          <div className={styles.ibpImage}>
            <Img fluid={ibpFluid} />
          </div>
        )}
        {/* <div className={styles.itemImageWrapper} style={{ color, backgroundColor: bgColor }}> */}
        {/* <i className={`icon-${icon}`} /> */}
      </div>

      <div className={styles.itemContentWrapper}>
        <div className={styles.itemContent}>
          <p className={styles.itemLabel}>{label}</p>
          {!!btcDiscount && (
            <p className={styles.itemDescription}>
              <span> -5% discount via E-Wallet or BTC</span>
            </p>
          )}
          {/*{!!isAnnual && (*/}
          {/*  <p className={styles.itemDescription}>*/}
          {/*    <span>{btcDiscountMessage}</span>*/}
          {/*  </p>*/}
          {/*)}*/}
          {!!recurrentPrice && !isAnnual && (
            <p className={styles.itemDescription}>
              {`$${recurrentPrice} ${recurringText}`}
              &nbsp;
              <span>{toMaintainTxt}</span>
            </p>
          )}
        </div>
        <div className={styles.priceContainer}>
          {!!btcDiscount && btcPrice && <span className={styles.oldPrice}>{`$${price}`}</span>}
          <span className={styles.itemPrice}>{`$${itemPrice}`}</span>
        </div>
      </div>
    </div>
  );
}

CheckoutItem.defaultProps = { recurrentPrice: 0, btcDiscount: 0, btcPrice: 0 };

CheckoutItem.propTypes = {
  label: PropTypes.string.isRequired,
  recurrentPrice: PropTypes.number,
  btcDiscount: PropTypes.number,
  btcPrice: PropTypes.number,
  price: PropTypes.number.isRequired,
  // icon: PropTypes.string.isRequired,
  // bgColor: PropTypes.string.isRequired,
  // color: PropTypes.string.isRequired,
  isAnnual: PropTypes.bool.isRequired,
  intervalSize: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ibpFluid: PropTypes.object.isRequired,
  imageUrl: PropTypes.string.isRequired,
};
